<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="库存期间">
              <el-date-picker
                v-model="form.inventoryPeriod"
                value-format="yyyy-MM"
                type="month"
                placeholder="请选择"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货主" prop="outboundCode">
              <el-select v-model="form.cargoOwnerCode" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in cargoOwnerList"
                  :key="item.logicWarehouseCode"
                  :value="item.cargoOwnerCode"
                  :label="item.cargoOwnerName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="逻辑仓名称" prop="outboundCode">
              <el-select v-model="form.logicWarehouseCode" clearable filterable placeholder="请选择">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库类型" prop="outboundCode">
              <el-select v-model="form.warehouseType" filterable clearable placeholder="请选择">
                <el-option label="公司仓" value="1" />
                <el-option label="供应商仓库" value="2" />
                <el-option label="自管仓" value="3" />
                <el-option label="第三方仓" value="4" />
                <el-option label="平台仓" value="5" />
                <el-option label="工厂仓" value="6" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU" prop="outboundCode">
              <el-input v-model.trim="form.sku" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" prop="outboundCode">
              <el-select v-model="form.style" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :value="item.styleName"
                  :label="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Color" prop="outboundCode">
              <el-select v-model="form.color" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :value="item.colorName"
                  :label="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Size" prop="outboundCode">
              <el-select v-model="form.sizes" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :value="item.size"
                  :label="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :span="24" class="mb-3">
          <el-col :span="24" style="display:flex;height:36px;">
             <el-button @click="handleExport">导出</el-button>
          </el-col>
        </el-row> -->
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">概览数据</el-col>
    </el-row>
    <div style="width: 100;display: flex;justify-content: space-around;">
      <div v-for=" (val,index) in datas" :key="index" style="margin-bottom: 10px;width:15%;text-align: center;border: 1px solid;">
        <p>{{ val.name }}</p>
        <p>{{ stockTotals[val.value] }}</p>
      </div>
    </div>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">明细数据</span>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="cargoOwnerCode" label="货主" align="center" />
      <el-table-column prop="sourceWarehouseName" label="逻辑仓名称" width="110" align="center" />
      <el-table-column prop="warehouseType" label="仓库类型" align="center">
        <template slot-scope="scope">{{ getWarehouseTypeLabel(scope.row.warehouseType) }}</template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU" width="180" align="center" />
      <el-table-column prop="style" label="Style" width="110" align="center" />
      <el-table-column prop="color" label="Color" width="110" align="center" />
      <el-table-column prop="size" label="Size" width="110" align="center" />
      <el-table-column prop="inventoryPeriod" label="库存期间" width="110" align="center" />
      <el-table-column prop="beginningQuantity" label="期初数量" width="110" align="center" />
      <el-table-column prop="purchaseInQuantity" label="采购入库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantity:scope.row.purchaseInQuantity,quantityName:'采购入库数量',businessType:'INVOICING_PURCHASE_IN', row:JSON.stringify(scope.row) }}">{{ scope.row.purchaseInQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="transferInQuantity" label="调拨入库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'采购入库数量',quantity:scope.row.transferInQuantity, businessType:'INVOICING_TRANSFER_IN',row:JSON.stringify(scope.row) }}">{{ scope.row.transferInQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="checkFullInQuantity" label="盘盈入库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: { quantityName:'采购入库数量',quantity:scope.row.checkFullInQuantity,businessType:'INVOICING_CHECK_FULL',row:JSON.stringify(scope.row) }}">{{ scope.row.checkFullInQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="otherInQuantity" label="其他入库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'其他入库数量',quantity:scope.row.otherInQuantity,businessType:'INVOICING_OTHER_IN', row:JSON.stringify(scope.row) }}">{{ scope.row.otherInQuantity }}</router-link></template>
      </el-table-column>
      <el-table-column prop="sellOutQuantity" label="销售出库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'销售出库数量',quantity:scope.row.sellOutQuantity,businessType:'INVOICING_SELL_OUT', row:JSON.stringify(scope.row) }}">{{ scope.row.sellOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="transferOutQuantity" label="调拨出库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'调拨出库数量',quantity:scope.row.transferOutQuantity, businessType:'INVOICING_TRANSFER_OUT',row:JSON.stringify(scope.row) }}">{{ scope.row.transferOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="innerOutQuantity" label="内购出库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: { quantityName:'内购出库数量',quantity:scope.row.innerOutQuantity,businessType:'INVOICING_INNER_OUT',row:JSON.stringify(scope.row) }}">{{ scope.row.innerOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="otherOutQuantity" label="其他出库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'其他出库数量',quantity:scope.row.otherOutQuantity, businessType:'INVOICING_OTHER_OUT', row:JSON.stringify(scope.row) }}">{{ scope.row.otherOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="scrapOutQuantity" label="报废出库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'报废出库数量',quantity:scope.row.scrapOutQuantity,businessType:'INVOICING_SCRAP_OUT', row:JSON.stringify(scope.row) }}">{{ scope.row.scrapOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="purchaseReturnQuantity" label="采购退货数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'采购退货数量',quantity:scope.row.purchaseReturnQuantity,businessType:'INVOICING_PURCHASE_RETURN', row:JSON.stringify(scope.row) }}">{{ scope.row.purchaseReturnQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="checkDeficitQuantity" label="盘亏出库数量" width="120" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ append:true, path: 'details', query: {quantityName:'盘亏出库数量',quantity:scope.row.checkDeficitQuantity, businessType:'INVOICING_CHECK_DEFICIT',row:JSON.stringify(scope.row) }}">{{ scope.row.checkDeficitQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="endingQuantity" label="期末结存数量" width="120" align="center" />
    </el-table>
    <Paging :pager="pager" @update="update" />
  </div>
</template>
<script>
import Paging from '@/components/Paging'
import { pageInvoicing } from '@/api/inventory-result'
import { getWarehouse } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
import { parseTime } from '@/utils'
import { getCargoOwner, queryColorList, querySizeList, queryStyleList } from '@/api/listSelection'
export default {
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      warehouseList: [],
      headlineProductsList: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      cargoOwnerList: [],
      form: {
        inventoryPeriod: '',
        cargoOwnerCode: '',
        logicWarehouseCode: '',
        sku: '',
        sizes: '',
        color: '',
        style: '',
        warehouseType: ''
      },
      datas: [
        { name: '期初总数量', value: 'totalBeginningQuantity' },
        { name: '入库总数量', value: 'totalInQuantity' },
        { name: '出库总数量', value: 'totalOutQuantity' },
        { name: '期末结存总数量', value: 'totalEndingQuantity' }

      ],
      stockTotals: {},
      tableLoading: false,
      tableData: [],
      showRow: false, // 切换显示input
      pager: {
        size: 20,
        current: 1,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      const { inventoryPeriod, cargoOwnerCode, logicWarehouseCode, sku, sizes, color, style, warehouseType } = this.form
      return Object.assign({}, this.pager, { inventoryPeriod, cargoOwnerCode, logicWarehouseCode, sku, sizes, color, style, warehouseType })
    }
  },
  mounted() {
    var preDate = new Date().setMonth((new Date()).getMonth() - 1)
    this.form.inventoryPeriod = parseTime(preDate, '{y}-{m}')
    this._pageInvoicing()
    this._queryWarehouseList()
    this._queryStyleList()
    this._queryColorList()
    this._querySizeList()
    this._getCargoOwner()
  },
  methods: {
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 选择style 带出color
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.styleName === value)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
      // this.form.color = ''
      // this.form.sizes = ''
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    queryClick() {
      this.pager.current = 1
      this._pageInvoicing()
    },
    resetClick() { // 点击重置清空文本框信息
      Object.assign(this.form, this.$options.data.call(this).form)
      this._pageInvoicing()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    async _pageInvoicing(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records, totals }} = await pageInvoicing(this.queryParams)
        this.pager = pager
        this.tableData = records || []
        this.stockTotals = totals || ''
      } finally {
        this.tableLoading = false
      }
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    },
    async _getCargoOwner() {
      const { datas } = await getCargoOwner()
      this.cargoOwnerList = datas
    },
    update(val) {
      console.log(val)
      this.pager = val
      this._pageInvoicing()
    }

  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
